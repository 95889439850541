window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    wsHost: process.env.MIX_PUSHER_WS,
    wsPort: parseInt(process.env.MIX_PUSHER_PORT), //6001
    wssPort: 443, //6001
    disableStats: true,
    forceTLS: (process.env.MIX_PUSHER_USE_SSL == "true") ? true : false,
    enabledTransports: ['ws', 'wss'], // <-- only use ws and wss as valid trans$
});
