require('../../../../../../resources/js/bootstrap');
import {changeRoom} from '../../../../../../resources/js/frontend';
let navi;
export default async(modul_id,actualRoom,settings) =>
{
    if(typeof(imageMapResize) !== "function")
    {
        await new Promise((resolve, reject) => {
             var script = document.createElement('script');
             script.onload = () =>  resolve();
             script.src = "/js/modules/mapnavigation/imageMapResizer.js";
             document.head.appendChild(script);
        })
    }

    const data = await (await fetch(`/room/${actualRoom}/module/MapNavigation/api?action=navigation`)).json();

    await buildMapNavigation(data.navi, modul_id);

    await new Promise((resolve, reject) => {
        let map_image = document.getElementById('mapnavigation_'+modul_id).querySelector(".map_image");
         map_image.onload = () =>  resolve();
         map_image.src = `/room/${actualRoom}/module/MapNavigation/public?action=image&name=${data.map_image}`;
    })

    await rescaleImageMap(modul_id);
    window.addEventListener('resize', function() {
        rescaleImageMap(modul_id);
    });

    Echo.channel('activities')
        .listen('NavigationUpdate', async(roomListEvent) => {
            const data = await (await fetch(`/room/${actualRoom}/module/MapNavigation/api?action=navigation`)).json();
            await buildMapNavigation(data.navi, modul_id);

            //Check if Map Image needs to be changed
            let map_navi = document.getElementById('mapnavigation_'+modul_id);
            console.log(map_navi.querySelector(".map_image").src.split("name=")[1]+" != "+data.map_image);

            if(map_navi.querySelector(".map_image").src.split("name=")[1] != data.map_image)
            {
                await new Promise((resolve, reject) => {
                    let map_image = document.getElementById('mapnavigation_'+modul_id).querySelector(".map_image");
                     map_image.onload = () =>  resolve();
                     map_image.src = `/room/${actualRoom}/module/MapNavigation/public?action=image&name=${data.map_image}`;
                })
            }
            rescaleImageMap(modul_id);

        });
    document.getElementById('mapnavigation_'+modul_id+'').querySelector(".map_image_container").style.opacity = 1.0;
    console.log('%c 📍 MapNavigationModule Loaded! ', 'background: #222; color: #bada55');

}


function buildMapNavigation(roomList,modul_id) {
        navi = document.getElementById('mapnavigation_'+modul_id+'_map');
        navi.innerHTML = "";
        //console.log(roomList,modul_id);

        for(let i = 0; i < roomList.length; i++){
            const room = roomList[i]; //class, target name, type, value, object
            const naviTemplate = document.getElementById('mapnavigation_'+modul_id+'_element');

            let clone = document.importNode(naviTemplate.content, true);
            let link = clone.querySelector("area");
            link.shape = room.shape;
            link.coords = room.coords;
            link.alt= room.alt;
            link.dataset.type = room.type;
            link.dataset.show_label = room.show_label;
            link.dataset.bg_color = room.bg_color;
            link.dataset.text_color = room.text_color;
            link.dataset.hover_bg_color = room.hover_bg_color;
            link.dataset.hover_text_color = room.hover_text_color;
            link.dataset.bg_color_alpha = room.bg_color_alpha;
            link.dataset.text_color_alpha = room.text_color_alpha;
            link.dataset.hover_bg_color_alpha = room.hover_bg_color_alpha;
            link.dataset.hover_text_color_alpha = room.hover_text_color_alpha;
            link.dataset.show_overlay_image = room.show_overlay_image;
            link.dataset.image = room.image;
            link.dataset.hover_image = room.hover_image;
            link.dataset.popup_image = room.popup_image;
            link.dataset.navi_item_id= modul_id+"_"+i;
            link.dataset.class= room.class;

            if(room.checkbox == 1){
              link.target = "_blank";
            }
            if(room.class != null){
              link.classList.add(room.class);
            }
            link.style = link.style += (room.css ?? "");
            switch(room.type)
            {
                case "link":
                    link.title = room.name ?? room.value;
                    link.href = room.value;
                break;
                case "room":
                    link.dataset.room_id = room.id;
                    link.title = room.name ?? room.object.longname;
                    link.dataset.room = room.object.slug;
                    link.href = "/room/" + room.object.slug;
                break;
                case "image":
                    link.title = room.name ?? "";
                break;
            }
            if(room.type == "image" && (room.popup_image == null || room.popup_image == ""))  {
                link.classList.add("no_action");
            }

          link.addEventListener('click', (event) => {
              switch(event.target.dataset.type)
              {
                  case "link":
                  break;
                  case "room":
                    event.preventDefault();
                    changeRoom("/room/" +event.target.dataset.room);
                  break;
                  case "image":
                  event.preventDefault();
                    if(event.target.dataset.popup_image == ("null" || "")) return;
                    var popup_image_modal = navi.querySelector(".popup_image_container .modal");
                    //console.log(event.target);
                      popup_image_modal.style.display = "block";
                      popup_image_modal.querySelector("img").src = `/room/${actualRoom}/module/MapNavigation/public?action=image&name=${event.target.dataset.popup_image}`;
                     if(event.target.alt != "null" && event.target.alt != "")
                     {
                         popup_image_modal.querySelector(".caption").style.display ="block";
                         popup_image_modal.querySelector(".caption").innerHTML = event.target.alt;

                     }
                     else
                     {
                         popup_image_modal.querySelector(".caption").style.display ="none";
                         popup_image_modal.querySelector(".caption").innerHTML = "";
                     }
                    // Get the <span> element that closes the modal
                    var span = popup_image_modal.querySelector(".close");
                    // When the user clicks on <span> (x), close the modal
                    span.onclick = function() {
                      popup_image_modal.style.display = "none";
                    }
                    popup_image_modal.onclick = function(event) {
                      popup_image_modal.style.display = "none";
                    }
                  break;
              }

              //console.log("Navi Click Event ", event);
          });

            if(room.show_label)
             {
                 link.addEventListener('mouseover', (event) =>
                 {
                     let navi_item = document.getElementById(`navi_item_${modul_id}_${i}`);
                    navi_item.style.backgroundColor = hexToRgbA(event.target.dataset.hover_bg_color,event.target.dataset.hover_bg_color_alpha);
                    navi_item.style.color = hexToRgbA(event.target.dataset.hover_text_color,event.target.dataset.hover_text_color_alpha);
                 });
                 link.addEventListener('mouseout', (event) =>
                 {
                     let navi_item = document.getElementById(`navi_item_${modul_id}_${i}`);
                     navi_item.style.backgroundColor = hexToRgbA(event.target.dataset.bg_color,event.target.dataset.bg_color_alpha);
                     navi_item.style.color = hexToRgbA(event.target.dataset.text_color,event.target.dataset.text_color_alpha);
                 });
             }
             else if(room.show_overlay_image && room.hover_image)
             {
                 link.addEventListener('mouseover', (event) =>
                 {
                     document.getElementById(`navi_item_${modul_id}_${i}`).style.backgroundImage = `url('/room/${actualRoom}/module/MapNavigation/public?action=image&name=${room.hover_image}')`;
                 });
                 link.addEventListener('mouseout', (event) =>
                 {
                     document.getElementById(`navi_item_${modul_id}_${i}`).style.backgroundImage = `url('/room/${actualRoom}/module/MapNavigation/public?action=image&name=${room.image}')`;
                 });
             }
            navi.appendChild(clone);

        }
    //    debugger;

}

function buildOverlays(modul_id)
{

    navi = document.getElementById('mapnavigation_'+modul_id);
    var map_image_areas = navi.querySelectorAll('.map_image_container area');
    navi.querySelector(".map_image_labels").innerHTML = "";
//console.log("buildOverlays",map_image_areas);
  [].forEach.call(map_image_areas, function(area) {

      if(area.getAttribute('shape') != "rect") return;
      //console.log(area);
      // takes the coordinates
      var title = area.getAttribute('title');
      var coor = area.getAttribute('coords');

      var coorA = coor.split(',');
      var left = parseInt(coorA[0]);
      var top = parseInt(coorA[1]);
      var right = parseInt(coorA[2]);
      var bottom = parseInt(coorA[3]);

      // in order to properly calculate the height and width
      // left position must be less than the right
      if (left > right) {
          var tmp = right;
          right = left;
          left = tmp;
      }
      // The same applies to top and bottom
      if (top > bottom) {
          var tmp = top;
          top = bottom;
          bottom = tmp;
      }
      // has an error / bug when the mouse moves upward seat2 map will not hide
      // this works
      top--;

      // calculate the width and height of the rectangle
      var width = right - left;
      var height = bottom - top;

      // sets the position, the sizes and text for title
      var newDiv = document.createElement("div");
      newDiv.id = `navi_item_${area.dataset.navi_item_id}`;
      newDiv.classList.add(`${area.dataset.class}`);

      if(area.dataset.show_label == "true")
      {
          newDiv.innerHTML = `<span>${title}</span>`;
          newDiv.setAttribute("style",`background-color:${hexToRgbA(area.dataset.bg_color,area.dataset.bg_color_alpha)};color:${hexToRgbA(area.dataset.text_color,area.dataset.text_color_alpha)};top: ${top}px;left: ${left}px;width: ${width}px;height: ${height}px;`)// füge das neu erstellte Element und seinen Inhalt ins DOM ein
          document.getElementById('mapnavigation_'+modul_id).querySelector(".map_image_labels").appendChild(newDiv);
          fitText(document.getElementById(`navi_item_${area.dataset.navi_item_id}`),5);
      }
      else if(area.dataset.show_overlay_image == "true" && area.dataset.image != "null")
      {
          const image_url= (`/room/${actualRoom}/module/MapNavigation/public?action=image&name=`+area.dataset.image);
          newDiv.setAttribute("style",`background-image:url(${image_url});top: ${top}px;left: ${left}px;width: ${width}px;height: ${height}px;`)// füge das neu erstellte Element und seinen Inhalt ins DOM ein
          navi.querySelector(".map_image_labels").appendChild(newDiv);
      }



  });

}


function rescaleImageMap(modul_id) {
    navi = document.getElementById('mapnavigation_'+modul_id);
    const map_image = navi.querySelector(".map_image");
    const map_image_width = map_image.naturalWidth;
    const map_image_height = map_image.naturalHeight;
    const map_image_ratio = map_image_width/map_image_height;

    var window_width = window.innerWidth;
    var window_height = window.innerHeight;
    var window_ratio = window_width/window_height;

    var mapImageTargetWidth, mapImageTargetHeight, mapImageTargetPositionX, mapImageTargetPositionY;

    if(window_ratio < map_image_ratio)
      {
          mapImageTargetWidth = window_height*map_image_ratio;
          mapImageTargetPositionX = (window_width-mapImageTargetWidth)/2;
          mapImageTargetHeight = window_height;
          mapImageTargetPositionY = 0;
      }
      else {
          mapImageTargetHeight = window_width/map_image_ratio;
          mapImageTargetPositionY = (window_height-mapImageTargetHeight)/2;
          mapImageTargetWidth = window_width;
          mapImageTargetPositionX = 0;
      }

      map_image.style.marginLeft = mapImageTargetPositionX+"px";
      map_image.style.marginTop =  mapImageTargetPositionY+"px";
      map_image.style.width = mapImageTargetWidth+"px";
      map_image.style.height = mapImageTargetHeight+"px"

      const map_labels = navi.querySelector(".map_image_labels");
      map_labels.style.marginLeft = mapImageTargetPositionX+"px";
      map_labels.style.marginTop =  mapImageTargetPositionY+"px";
      map_labels.style.width = mapImageTargetWidth+"px";
      map_labels.style.height = mapImageTargetHeight+"px"
      imageMapResize(document.getElementById('mapnavigation_'+modul_id+'_map'));
      buildOverlays(modul_id);
}

function hexToRgbA(hex,alpha){
  var c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length== 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+alpha+')';
  }
  throw new Error('Bad Hex');
}

function fitText(outputElement,paddingPx){
    // max font size in pixels
    const maxFontSize = 30;
    // get element's width
    let width = outputElement.clientWidth;
    // get content's height
    let contentWidth = outputElement.querySelector("span").scrollWidth;
    let height = outputElement.clientHeight;
    // get content's height
    let contentHeight = outputElement.querySelector("span").scrollHeight;

    // get fontSize
    let fontSize = parseInt(window.getComputedStyle(outputElement, null).getPropertyValue('font-size'),10);

    // if content's width is bigger then elements width - overflow

    if (contentWidth > width-(2*paddingPx)){
        fontSize = Math.ceil(fontSize * (width-(2*paddingPx))/contentWidth,10);
        fontSize =  fontSize > maxFontSize  ? fontSize = maxFontSize  : fontSize - 1;
        outputElement.style.fontSize = fontSize+'px';
        //console.log("wider")
    }
    //ubdate height
    contentHeight = outputElement.querySelector("span").scrollHeight;
    if (contentHeight > height-(2*paddingPx)){
        fontSize = Math.ceil(fontSize * (height-(2*paddingPx))/contentHeight,10);
        fontSize =  fontSize > maxFontSize  ? fontSize = maxFontSize  : fontSize - 1;
        outputElement.style.fontSize = fontSize+'px';
        //console.log("heigher")
    }
    else{
        //console.log("smaller");
        // content is smaller then width... let's resize in 1 px until it fits
        while (contentWidth === width && contentHeight === height && fontSize < maxFontSize){
            fontSize = Math.ceil(fontSize) + 1;
            fontSize = fontSize > maxFontSize  ? fontSize = maxFontSize  : fontSize;
            outputElement.style.fontSize = fontSize+'px';
            // update widths
            width = outputElement.clientWidth;
            contentWidth = outputElement.querySelector("span").scrollWidth;
            height = outputElement.clientHeight;
            contentHeigt = outputElement.querySelector("span").scrollHeight;
            if (contentWidth > width-(2*paddingPx) || contentHeight > height-(2*paddingPx)){
                outputElement.style.fontSize = fontSize-1+'px';
            }
        }
    }
}
